import imgProxy from "~/utils/imgproxy";
import { GdprPermissionRequest } from "~/components/shared/GDPR/GdprPermissionRequest";
import { Show } from "solid-js";
import { useDidomiContext } from "~/contexts/DidomiContext";
import { Portal } from "solid-js/web";

import "./SeoText.css";
import "~/components/shared/WysiwygComponents.css";

export default function SeoTextBis() {
  const [, { hasGivenConsentForUrl }] = useDidomiContext();

  return (
    <>
      <div class="content-part block-seo" data-test="block-seo-bis">
        <section class="revolution" id="revolution">
          <h2>
            Devenir propriétaire&nbsp;?
            <br />
            Ce matin, vous n’y pensiez pas. Ce soir, vous ne penserez qu’à
            ça&nbsp;!
          </h2>
          <div class="video-container">
            <Show
              when={hasGivenConsentForUrl!(
                "https://www.youtube.com/embed/CvZW7AbFssU",
              )}
              fallback={
                <div class="comp-embed-container">
                  <GdprPermissionRequest
                    string={"https://www.youtube.com/embed/CvZW7AbFssU"}
                  />
                </div>
              }
            >
              <div class="comp-video">
                <iframe
                  src="https://www.youtube.com/embed/CvZW7AbFssU"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </Show>
          </div>
        </section>

        <section class="ambition" id="ambition">
          <h2>L’accès à la propriété n’a jamais été aussi accessible</h2>
          <div class="row">
            <div class="text-part">
              <p>
                Nous avons mobilisé nos banques partenaires et utilisé au mieux
                les avantages du nouveau Prêt à Taux Zéro en les abondant. Nous
                avons aussi repensé l’ensemble de nos process de conception et
                de développement, sans rien céder à la qualité, ni de nos
                réalisations, ni de notre service client.
              </p>
              <h3>Notre unique ambition ?</h3>
              <p>
                Vous proposer des <strong>logements au prix juste</strong> et un{" "}
                <strong>financement adapté</strong> à votre pouvoir d’achat avec
                des <strong>mensualités compatibles</strong> avec votre actuel
                budget logement mensuel en tant que locataire.
              </p>
            </div>
            <picture class="visual">
              <img
                src={imgProxy(
                  "/images/nouveau-neuf/access-visual-medium-2.jpg",
                  `size:400:340/resizing_type:fill`,
                )}
                alt=""
                height="340"
                width="400"
                loading="lazy"
              />
            </picture>
          </div>
          <div class="button">
            <a href="#budget-logement" class="btn">
              Trouver un logement
            </a>
          </div>
        </section>

        <section class="camille" id="camille">
          <h2>Comme eux pour la première fois, devenez propriétaire</h2>
          <div class="row with-margin">
            <div class="text-part">
              <h3>
                Votre 2 pièces pour{" "}
                <span class="nowrap">
                  588 €/mois<sup>(2)</sup>
                </span>
              </h3>
              <p>
                <strong class="black">Sarah</strong>,
                <br />
                Infirmière, Habite à Angers
              </p>
              <p>
                <strong class="black">Salaire du foyer&nbsp;:</strong>{" "}
                <span class="nowrap">1 562 €/mois</span>
                <br />
                <strong class="black">Loyer actuel&nbsp;:</strong>{" "}
                <span class="nowrap">544 €/mois</span>
                <br />
                <strong>Avec Cogedim Access&nbsp;:</strong> propriétaire de son
                appartement de 2 pièces avec balcon et 1 place de stationnement
                pour{" "}
                <span class="nowrap">
                  588 €/mois<sup>(2)</sup>
                </span>{" "}
                en TVA réduite à{" "}
                <span class="nowrap">
                  5,5 %<sup>(4)</sup>
                </span>
                .
              </p>
            </div>
            <div class="video-container">
              <Show
                when={hasGivenConsentForUrl!(
                  "https://www.youtube.com/embed/EvuPuqK_jwI",
                )}
                fallback={
                  <div class="comp-embed-container">
                    <GdprPermissionRequest
                      string={"https://www.youtube.com/embed/EvuPuqK_jwI"}
                    />
                  </div>
                }
              >
                <div class="comp-video">
                  <iframe
                    src="https://www.youtube.com/embed/EvuPuqK_jwI"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </Show>
            </div>
          </div>
          <div class="row">
            <div class="text-part">
              <h3>
                Votre 3 pièces pour{" "}
                <span class="nowrap">
                  1 015 €/mois<sup>(3)</sup>
                </span>
              </h3>
              <p>
                <strong class="black">Alice et Théo</strong>,
                <br />
                Vendeuse et ambulancier, habitent à Villeneuve-la-Garenne
              </p>
              <p>
                <strong class="black">Salaire du foyer&nbsp;:</strong>{" "}
                <span class="nowrap">3 250 €/mois</span>
                <br />
                <strong class="black">Loyer actuel&nbsp;:</strong>{" "}
                <span class="nowrap">950 €/mois</span>
                <br />
                <strong>Avec Cogedim Access&nbsp;:</strong> propriétaires d’un 3
                pièces avec balcon et 1 place de stationnement pour{" "}
                <span class="nowrap">
                  1 015 €/mois<sup>(3)</sup>
                </span>{" "}
                en TVA réduite à{" "}
                <span class="nowrap">
                  5,5 %<sup>(4)</sup>
                </span>
                .
              </p>
            </div>
            <div class="video-container">
              <Show
                when={hasGivenConsentForUrl!(
                  "https://www.youtube.com/embed/v9HGEdRv3PY",
                )}
                fallback={
                  <div class="comp-embed-container">
                    <GdprPermissionRequest
                      string={"https://www.youtube.com/embed/v9HGEdRv3PY"}
                    />
                  </div>
                }
              >
                <div class="comp-video">
                  <iframe
                    src="https://www.youtube.com/embed/v9HGEdRv3PY"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </Show>
            </div>
          </div>
          <div class="button">
            <a href="#accompagnement" class="btn">
              En savoir plus
            </a>
          </div>
        </section>
      </div>

      <Portal mount={document.getElementById("legals-offer-receiver")!}>
        <p class="legals">
          (2) Exemple de mensualité pour une acquisition, en résidence
          principale, du lot 10025, appartement 2 pièces alcôve situé au 2ème
          étage de la résidence Rive Nature à Villeneuve-la-Garenne, d’une
          surface habitable de 49,59m² avec un balcon de 9,48 m² et 1 place de
          stationnement. Appartement vendu en l’état futur d’achèvement en TVA
          réduite à 5,5 % au prix de 229 500 €. Financement sans apport
          personnel réalisé grâce au PTZ, un PAS et à des prêts consentis auprès
          des partenaires bancaires du groupe ALTAREA par l’intermédiaire
          d’Altarea Solution Financement. TVA réduite à 5,5 % (acquisition pour
          10 ans minimum), PTZ et PAS, réservés aux primo-accédants pour l’achat
          d’un logement en résidence principale sont soumis à conditions de
          revenus. Montant total des crédits : 229 500,00 €. Durée totale des
          crédits : 25 ans. TAEG global estimé (y/c assurance obligatoire) de
          1,68 %. Coût total du crédit (y/c assurance) : 51 711,11 €. Conditions
          détaillées sur{" "}
          <a
            href="https://www.cogedim.com/mlaccessleaflet"
            target="_blank"
            class="ext"
            rel="noopener noreferrer nofollow"
          >
            www.cogedim.com/mlaccessleaflet
          </a>
          . Exemple communiqué, sans valeur contractuelle, établi sur la base de
          taux moyens du marché constatés au 04/04/2024 pour diverses situations
          comparables. Altarea Solution Financement est une société de courtage
          en crédit immobilier et en assurances. Siège social : 87 Rue de
          Richelieu 75002 Paris. SAS au capital de 10 000 € immatriculée au
          R.C.S Paris sous le N°504 638 784 et au registre de l’Orias
          (www.orias.fr) sous le N°10053430. L’emprunteur dispose d’un délai
          légal de réflexion de 10 jours. Lorsque la vente est subordonnée à
          l’obtention d’un prêt et si celui-ci n’est pas obtenu, le vendeur doit
          rembourser les sommes versées par l’acquéreur. Le versement des
          mensualités, hors assurances, débutera à la date de livraison de
          l’appartement. Un crédit vous engage et doit être remboursé. Vérifiez
          vos capacités de remboursement avant de vous engager. Sous réserve
          d’acceptation de votre dossier par nos partenaires bancaires. Cogedim
          SAS, 87, rue de Richelieu 75002 Paris, RCS PARIS n° 054&nbsp;500 814 -
          SIRET 05 450 081 400&nbsp;063.
        </p>

        <p class="legals">
          (3) Exemple de mensualité pour une acquisition, en résidence
          principale, du lot 070304, appartement 3 pièces situé au 3ème étage de
          la résidence Rive Nature à Villeneuve-la-Garenne, d’une surface
          habitable de 57,76 m² avec un balcon de 10,12 m² et 1 place de
          stationnement. Appartement vendu en l’état futur d’achèvement en TVA
          réduite à 5,5 % au prix de 263 000 €. Financement réalisé avec un
          apport personnel de 5 000 € et grâce à un prêt PTZ, un prêt classique
          et à des prêts consentis auprès des partenaires bancaires du groupe
          ALTAREA par l’intermédiaire d’Altarea Solution Financement. TVA
          réduite à 5,5 % (acquisition pour 10 ans minimum) et PTZ, réservés aux
          primo-accédants pour l’achat d’un logement en résidence principale
          sont soumis à conditions de revenus. Montant total des crédits : 258
          000,00 €. Durée totale des crédits : 25 ans. TAEG global estimé (y/c
          assurance obligatoire) de 1,36 %. Coût total du crédit (y/c assurance)
          : 46 449,20 €. Conditions détaillées sur{" "}
          <a
            href="https://www.cogedim.com/mlaccessleaflet"
            target="_blank"
            class="ext"
            rel="noopener noreferrer nofollow"
          >
            www.cogedim.com/mlaccessleaflet
          </a>
          . Exemple communiqué, sans valeur contractuelle, établi sur la base de
          taux moyens du marché constatés au 22/07/2024 pour diverses situations
          comparables. Altarea Solution Financement est une société de courtage
          en crédit immobilier et en assurances. Siège social : 87 Rue de
          Richelieu 75002 Paris. SAS au capital de 10 000 € immatriculée au
          R.C.S Paris sous le N°504 638 784 et au registre de l’Orias
          (www.orias.fr) sous le N°10053430. L’emprunteur dispose d’un délai
          légal de réflexion de 10 jours. Lorsque la vente est subordonnée à
          l’obtention d’un prêt et si celui-ci n’est pas obtenu, le vendeur doit
          rembourser les sommes versées par l’acquéreur. Le versement des
          mensualités, hors assurances, débutera à la date de livraison de
          l’appartement. Un crédit vous engage et doit être remboursé. Vérifiez
          vos capacités de remboursement avant de vous engager. Sous réserve
          d’acceptation de votre dossier par nos partenaires bancaires.
        </p>

        <p class="legals">
          (4) TVA réduite à 5,5 % au lieu de 20 %, applicable sous conditions de
          ressources pour une acquisition en résidence principale sur une durée
          de 10 ans minimum. Conditions détaillées sur{" "}
          <a
            href="https://www.cogedim.com/mlaccessleaflet"
            target="_blank"
            class="ext"
            rel="noopener noreferrer nofollow"
          >
            www.cogedim.com/mlaccessleaflet
          </a>
          .
        </p>
      </Portal>
    </>
  );
}
