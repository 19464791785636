import imgProxy from "~/utils/imgproxy";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import { isServer } from "solid-js/web";

import "./FirstScreen.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

type AnchorSelectOption = {
  url: string;
  text: string;
  class?: string;
  hasButtonAppearance?: boolean;
};

export default function FirstScreen() {
  const [viewPortState] = useViewportSizeContext();

  const [anchorsShouldStack, setAnchorsShouldStack] = createSignal(false);

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    if (window.scrollY > 580 - 100) {
      setAnchorsShouldStack(true);
    } else {
      setAnchorsShouldStack(false);
    }
  };

  const anchors: AnchorSelectOption[] = [
    {
      url: `#budget-logement`,
      text: `Mon budget logement`,
    },
    {
      url: `#avantages`,
      text: `Les avantages`,
    },
    {
      url: `#revolution`,
      text: `Une révolution immobilière`,
    },
    {
      url: `#ambition`,
      text: `Notre ambition`,
    },
    {
      url: `#camille`,
      text: `Faites comme eux`,
    },
    {
      url: `#residences-eligibles`,
      text: `Nos résidences éligibles`,
    },
    {
      url: `#accompagnement`,
      text: `Demander un accompagnement`,
      hasButtonAppearance: true,
    },
  ];

  return (
    <>
      <section class="full-visual" data-test="first-screen">
        <picture>
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:768:432/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:533:300/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              `size:533:300/resizing_type:fill`,
            )}
            alt=""
            height="300"
            width="375"
            loading="lazy"
          />
        </picture>

        <div class="heading" data-test="header">
          <img
            src="/images/nouveau-neuf/logo-cogedim-access.svg"
            alt="Cogedim Access"
            height="77"
            width="280"
          />
          <h1>
            <strong>Avec COGEDIM ACCESS</strong>, pour la première fois vous
            pouvez devenir propriétaire.
          </h1>
        </div>

        <div
          class="anchors"
          data-test="anchors"
          classList={{ stacked: anchorsShouldStack() }}
        >
          <Show
            when={viewPortState.viewPortIsLessThan768}
            fallback={
              <>
                <For each={anchors}>
                  {(anchor) => (
                    <a
                      href={anchor.url}
                      classList={{ btn: anchor.hasButtonAppearance }}
                    >
                      {anchor.text}
                    </a>
                  )}
                </For>
              </>
            }
          >
            <FormInputSelectAnchors datas={anchors} />
          </Show>
        </div>
      </section>
    </>
  );
}

function FormInputSelectAnchors(props: { datas: AnchorSelectOption[] }) {
  const [inputHasContent, setInputHasContent] = createSignal(false);
  const [menuDialogIsActive, setMenuDialogIsActive] = createSignal(false);

  const [anchorIndex, setAnchorIndex] = createSignal<number | undefined>();

  createEffect(() => {
    if (
      typeof anchorIndex() !== "undefined" &&
      props.datas.at(anchorIndex()!)
    ) {
      setInputHasContent(true);
    } else {
      setInputHasContent(false);
    }
  });

  const inputValue = createMemo(() => {
    if (
      typeof anchorIndex() !== "undefined" &&
      props.datas.at(anchorIndex()!)
    ) {
      return props.datas.at(anchorIndex()!)?.text;
    }
    return undefined;
  });

  function setActiveAnchor(index: number) {
    setMenuDialogIsActive(false);
    setAnchorIndex(index);
    document.location.href = props.datas.at(index)!.url;
  }

  const [inputIsFocus, setInputIsFocus] = createSignal(false);

  return (
    <>
      <div
        class="form-control white-color"
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
        }}
      >
        <div class="form-slot form-select">
          <label for="visit-select">Menu</label>
          <input
            id="visit-select"
            name="visit_select"
            type="text"
            value={inputValue() ? inputValue() : ""}
            readonly
            onFocusIn={() => {
              setInputIsFocus(true);
              setMenuDialogIsActive(true);
            }}
            onBlur={() => {
              setInputIsFocus(false);
            }}
            data-test="menu"
          />
          <i aria-hidden="true" class="cog-icon">
            <IconExpandMore />
          </i>
          <div class="menu-dialog" classList={{ active: menuDialogIsActive() }}>
            <div
              class="dialog-overlay"
              onClick={() => setMenuDialogIsActive(false)}
            />
            <ul class="list" data-test="menu-items">
              <For each={props.datas}>
                {(anchor: AnchorSelectOption, index) => (
                  <>
                    <li
                      onClick={() => setActiveAnchor(index())}
                      classList={{
                        active: index() === anchorIndex(),
                      }}
                      data-test="item"
                    >
                      {anchor.text}
                    </li>
                  </>
                )}
              </For>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
