import { Portal } from "solid-js/web";

import "./SeoText.css";

export default function SeoText() {
  return (
    <>
      <div class="content-part block-seo" data-test="block-seo">
        <section class="avantages" id="avantages">
          <h2>Les avantages de Cogedim Access</h2>
          <h3>
            Vous êtes locataire&nbsp;? Devenez propriétaire en réglant des
            mensualités proches d’un loyer.
          </h3>
          <div class="cards" data-test="cards">
            <div class="card" data-test="card">
              <p class="inner">
                Même
                <strong>
                  sans apport<sup>(1)</sup>
                </strong>
              </p>
              <p class="hover">
                Et les frais de notaire, de dossiers bancaires et d’intérêts
                intercalaires sont offerts.
              </p>
            </div>
            <div class="card" data-test="card">
              <p class="inner">
                Seulement
                <strong>500 €</strong>{" "}
                <span>
                  à la réservation<sup>(1)</sup>
                </span>
              </p>
              <p class="hover">
                En lieu et place du montant légal de 2&nbsp;% ou 5&nbsp;% du
                montant TTC du prix de vente.
              </p>
            </div>
            <div class="card" data-test="card">
              <p class="inner">
                <strong>0 frais</strong>{" "}
                <span>
                  avant d’emménager<sup>(1)</sup>
                </span>
              </p>
              <p class="hover">
                Commencez à payer que le jour de votre emménagement.
              </p>
            </div>
          </div>
        </section>
      </div>

      <Portal mount={document.getElementById("legals-offer-receiver")!}>
        <p class="legals">
          (1) Exemple de mensualités pour une acquisition, en résidence
          principale, du Lot 10025, appartement 2 pièces alcôve situé 2ème étage
          de la résidence Rive Nature à Villeneuve-la-Garenne, d’une surface
          habitable de 49,59 m² avec un balcon de 9,48 m² et 1 place de
          stationnement. Appartement vendu en l’état futur d’achèvement en TVA
          réduite à 5,5 % au prix de 229 500,00€. Financement sans apport
          personnel réalisé grâce au PTZ, un PAS et à des prêts consentis auprès
          des partenaires bancaires du groupe ALTAREA par l’intermédiaire
          d’Altarea Solution Financement. TVA réduite à 5,5 % (acquisition pour
          10 ans minimum), PTZ et PAS, réservés aux primo-accédants pour l’achat
          d’un logement en résidence principale sont soumis à conditions de
          revenus. Montant total des crédits : 229 500 €. Durée totale des
          crédits : 25 ans. TAEG global estimé (y/c assurance obligatoire) de
          1,68 %. Coût total du crédit (y/c assurance) : 51 711,11 €. Conditions
          détaillées sur le site cogedim.com. Exemple communiqué, sans valeur
          contractuelle, établi sur la base de taux moyens du marché constatés
          au 4/04/2024 pour diverses situations comparables. Altarea Solution
          Financement est une société de courtage en crédit immobilier et en
          assurances. Siège social : 87 Rue de Richelieu 75002 Paris. SAS au
          capital de 10 000 € immatriculée au R.C.S Paris sous le N°504 638 784
          et au registre de l’Orias (www.orias.fr) sous le N°10053430.
          L’emprunteur dispose d’un délai légal de réflexion de 10 jours.
          Lorsque la vente est subordonnée à l’obtention d’un prêt et si
          celui-ci n’est pas obtenu, le vendeur doit rembourser les sommes
          versées par l’acquéreur. Le versement des mensualités, hors
          assurances, débutera à la date de livraison de l’appartement. Un
          crédit vous engage et doit être remboursé. Vérifiez vos capacités de
          remboursement avant de vous engager. Sous réserve d'acceptation de
          votre dossier par nos partenaires bancaires. Cogedim SAS, 87, rue de
          Richelieu 75002 Paris, RCS PARIS n° 054 500 814 - SIRET 05 450 081 400
          063. Conditions complètes consultables{" "}
          <a
            href="https://www.cogedim.net/animations/brochure/Cogedim_Access_Details_Simulation.pdf"
            target="_blank"
            class="ext"
            rel="noopener noreferrer nofollow"
          >
            ici.
          </a>
        </p>
      </Portal>
    </>
  );
}
