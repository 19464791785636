import imgProxy from "~/utils/imgproxy";

import "./SeoText.css";

export default function SeoTextIntro() {
  return (
    <>
      <div class="content-part block-seo" data-test="block-seo">
        <section class="acces-propriete">
          <h2>Cogedim lève les freins de l’accès à la propriété</h2>
          <div class="row">
            <div class="text-part">
              <p>
                Véritable révolution immobilière, Cogedim a créé une{" "}
                <strong>solution sur-mesure et unique, Cogedim Access</strong>,
                pour que l’achat immobilier devienne simple et accessible à
                tous.
              </p>
              <p>
                Plus besoin d’apport ou de frais avant d’emménager. Cogedim
                Access vous ouvre les portes de la propriété.
              </p>
            </div>
            <picture class="visual">
              <img
                src={imgProxy(
                  "/images/nouveau-neuf/access-visual-medium.jpg",
                  `size:400:340/resizing_type:fill`,
                )}
                alt=""
                height="340"
                width="400"
                loading="lazy"
              />
            </picture>
          </div>
          <div class="button">
            <a href="#accompagnement" class="btn">
              En savoir plus
            </a>
          </div>
        </section>
      </div>
    </>
  );
}
